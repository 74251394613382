import React from "react";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import Header from "../components/Header";
import BookVisit from "../components/BookVisit";
import Footer from "../components/Footer";
import Reviews from "../components/Reviews";
import SocialLinks from "../components/SocialLinks";


const Testemunials = ({ data }) => (
  <Layout
    title={"O que dizem os clientes da EMMIM®? Deixem falar quem nos conhece melhor"}
    description="Quem somos nós para falar sobre nós? Nada melhor que os clientes da EMMIM® para falar de nós"
  >
    <Header
      className="testemonials"
      smallSubtitle="Deixem falar quem nos conhece melhor"
      title={
        <>
          <h1>Quem somos nós para falar sobre nós?</h1>
        </>
      }
      active="testemunhos"
    />

    <Fade bottom>
      <div className="container-fluid bg-fg-withLogo noGutters">
        <div className="row justify-content-md-center no-gutters">
          <Reviews title="o que dizem os nossos clientes?" />
        </div>
      </div>
    </Fade>

    <BookVisit
      title="E se pudesse ter o seu consultório no coração de Lisboa?"
      subtitle='Esqueça os "ses", porque agora já pode. Marque a sua visita e conheça o seu consultório'
    />
    <SocialLinks />
    <Footer />
  </Layout>
);


export default Testemunials;
